.button {
  padding: 10px 12px;
  background-color: #F2865E;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  color: #fcfcfc;
  border: 0;
  border-radius: 3px;
}
.button:hover {
  box-shadow: 0px 0 6px rgba(0, 0, 0, 0.75);
  text-decoration: none;
  cursor: pointer;
}
.button:visited {
  color: #fcfcfc;
}
.button:disabled {
  color: #a7a7a7;
  background-color: #e1e1e1;
  cursor: default;
  box-shadow: none;
}
