.hero {
  width: 100%;
}
.heroImage {
  z-index: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: -70px auto 0;
  position: relative;
  padding: 70px 20px 0;
  min-height: 300px;
  background-image: url(/img/babytrain.jpg);
  background-color: #f2f2f2;
}
.heroImage::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(90deg, #f2f2f2 0%, #f2f2f2 50%, rgba(242, 242, 242, 0) 100%);
}
.heroImage > * {
  position: relative;
  z-index: 2;
}
.heroImage h1 {
  padding-top: 34px;
  max-width: 366px;
  margin: 0 auto 20px 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  text-align: left;
}
.heroImage p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
  max-width: 290px;
}
.credits {
  position: absolute;
  margin-top: -40px;
  left: 50%;
  margin-left: -650px;
  font-size: 10px;
  color: #737373;
}
.credits a {
  color: #737373;
}
.functions {
  background: linear-gradient(#dbe5de 0%, #cdd4c0 50%, #94a098 100%);
  padding-bottom: 30px;
}
.function {
  text-align: center;
  color: #0d0d0d;
  width: 344px;
  margin: 0 auto;
}
.function h3 {
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  margin-bottom: 10px;
  position: relative;
  padding-top: 108px;
}
.function h3::before {
  content: "";
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  width: 56px;
  height: 56px;
  margin: 0 auto;
  background-size: contain;
}
.assist::before {
  background-image: url("/img/icon-award@2x.png");
}
.time::before {
  background-image: url("/img/icon-clock@2x.png");
}
.answer::before {
  background-image: url("/img/icon-chat@2x.png");
}

.benefits {
  position: relative;
  z-index: 2;
  padding: 220px 32px 0;
}
.benefits::before {
  position: absolute;
  z-index: 1;
  top: -21px;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  display: block;
  background-image: url(/img/mobile-fox@2x.png);
  background-size: auto 233px;
  background-repeat: no-repeat;
  background-position: top left;
}
.benefits h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 45px;
  text-align: center;
}

.benefits h2::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 80px;
  border-bottom: 2px solid #737f77;
}

.benefit {
  max-width: 344px;
  margin: 20px auto;
}

.benefit h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 37px;
  margin-bottom: 3px;
}
.benefit p {
  margin: 0;
}

.how {
  padding: 30px 32px;
  box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.16);
  background-image: url(/img/spacewall.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}
.how h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 45px;
  text-align: center;
}

.how h2::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 80px;
  border-bottom: 2px solid #737f77;
}

.join {
  padding: 43px 8px 60px;
  background: #ded1e8;
  text-align: center;
}

.join h2 {
  margin-bottom: 10px;
}

.subscribe {
  align-content: stretch;
  justify-content: center;
}

@media screen and (min-width: 431px) {
  .benefits {
    padding-top: 400px;
  }
  .benefits::before {
    top: -38px;
    background-size: 768px auto;
    background-position: top center;
  }
}

@media screen and (min-width: 768px) {
  .heroImage {
    margin-top: -120px;
    padding: 120px 32px 0;
  }
  .heroImage::before {
    width: 658px;
    right: auto;
  }

  .functions {
    display: flex;
    flex-wrap: wrap;
  }
  .benefits {
    padding-top: 40px;
    padding-left: 380px;
    padding-bottom: 20px;
    background-image: url(/img/fox_02@2x.jpg);
    background-size: auto 400px;
    background-repeat: no-repeat;
    background-position: top center;
  }
  .benefits::before {
    top: -40px;
    background-image: url(/img/fox_01@2x.png);
    background-size: auto 40px;
    background-position: top center;
  }
  .benefits::after {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 350px;
    bottom: 0;
    right: 0;
    content: "";
    display: block;
    background: linear-gradient(-90deg, #fff 0%, #fff 70%, rgba(255, 255, 255, 0) 100%);
  }
  .benefits > * {
    position: relative;
    z-index: 2;
  }
  .benefit {
    width: 280px;
    margin-bottom: 0;
  }
  .benefit h3 {
    font-size: 18px;
  }
  .benefit p {
    font-size: 14px;
    line-height: 18px;
  }
  .easy {
    padding-left: 40px;
  }
  .supportive {
    padding-left: 80px;
  }

  .how {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
  }
  .how h2 {
    width: 100%;
  }
  .how p {
    width: 280px;
  }
}

@media screen and (min-width: 1024px) {
  .heroImage {
    background-size: contain;
    background-position: center right;
    padding-left: calc(50% - 400px);
    background-image: url(/img/babytrain@2x.jpg);
  }
  .heroImage::before {
    width: 75%;
  }
  .heroImage h1 {
    font-size: 33px;
    line-height: 39px;
    max-width: 476px;
  }
  .heroImage p {
    max-width: 547px;
    font-size: 16px;
    letter-spacing: 0.02em;
  }

  .functions {
    padding: 0 calc((100% - 1080px) / 2) 53px;
  }

  .benefits {
    background-size: 1440px auto;
    padding-left: calc(100% - 517px);
    padding-top: 63px;
    height: 561px;
    box-sizing: border-box;
  }
  .benefits::before {
    top: -56px;
    background-size: 1440px auto;
  }
  .benefits::after {
    display: none;
  }
  .benefits h2 {
    font-size: 34px;
    line-height: 45px;
    margin-bottom: 18px;
    margin-left: -120px;
  }
  .benefits h2::after {
    margin-top: 18px;
  }
  .benefit {
    width: 330px;
    margin-left: 60px;
  }
  .benefit h3 {
    font-size: 25px;
    line-height: 37px;
  }
  .benefit p {
    font-size: 16px;
    line-height: 23px;
  }
  .easy {
    padding-left: 60px;
  }
  .supportive {
    padding-left: 120px;
  }

  .how {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
    background-image: url(/img/spacewall@2x.jpg);
    padding: 80px calc((100% - 751px) / 2) 60px;
  }
  .how h2 {
    width: 100%;
  }
  .how p {
    width: 280px;
  }
}

@media screen and (min-width: 1366px) {
  .heroImage {
    padding-left: 180px;
  }
  .benefits {
    padding-left: 800px;
  }
}
